import BackgroundImage from "../images/statsbackground.png"

export default function ThreeCircles() {
  return (
    <div className="relative w-full h-[500px] flex items-center justify-center bg-gray-900">
      {/* background image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-60"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></div>

      {/* stats container */}
      <div className="relative flex flex-wrap items-center justify-center gap-6 w-full max-w-5xl">
        {/* center circle */}
        <div className="relative w-64 h-64 bg-white rounded-full flex flex-col shadow-lg z-10">
          <div className="flex flex-col items-center justify-center h-full px-6 py-4">
            <h2 className="text-4xl font-bold text-red-900 mb-2">40+</h2>
            <p className="text-sm text-gray-700 max-w-[90%]">
              ACE Chapters that are dedicated to our mission of helping create study materials for students worldwide.
            </p>
          </div>
        </div>

        {/* left circle */}
        <div className="absolute left-0 bottom-0 -translate-x-10 w-56 h-56 bg-white rounded-full shadow-lg z-0">
          <div className="flex flex-col items-center justify-center h-full px-4 py-3">
            <h2 className="text-4xl font-bold text-red-900 mb-1">5K+</h2>
            <p className="text-sm text-gray-700 max-w-[90%]">Students impacted through ACE</p>
          </div>
        </div>

        {/* right circle */}
        <div className="absolute right-0 bottom-0 translate-x-10 w-56 h-56 bg-white rounded-full shadow-lg z-0">
          <div className="flex flex-col items-center justify-center h-full px-4 py-3">
            <h2 className="text-4xl font-bold text-red-900 mb-1">5+</h2>
            <p className="text-sm text-gray-700 max-w-[90%]">US States that ACE has expanded to</p>
          </div>
        </div>
      </div>
    </div>
  )
}
