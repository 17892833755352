import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../components/ui/accordion"
  
  export function AccordionDemo() {
    return (
      <Accordion type="single" collapsible className="w-1/2 mx-auto bg-transparent ">
        <AccordionItem value="item-1 " className="[&>div]:border-none [&>div]:shadow-none">
        <div className="bg-[#181818] rounded-md">
          <AccordionTrigger className="text-white bg-[#181818]">What is the chapter structure?</AccordionTrigger>
          <AccordionContent className="bg-[#181818]">
          Each ACE Chapter consists of a structure of Presidents, Vice Presidents, Subject Officers, and Class Representatives. The role of the presidents and vice presidents are to oversee operations and ensure material is being made along with holding successful study sessions. Subject officers are in charge of overseeing the courses under a specific subject. For example, the math subject officer would oversee subjects from Algebra 1 to AP Calculus BC. Subject officers ensure representatives make material on-time and help representatives make reviews. Class representatives are in charge of making the material for their assigned subjects, such as through making a slideshow review or practice test. They will also hold and lead study sessions for others to attend.


          </AccordionContent>
          </div>
        </AccordionItem>
        <AccordionItem value="item-2">
            <div className="bg-[#181818] rounded-md">
          <AccordionTrigger className="text-white bg-[#181818]">How are things done?</AccordionTrigger>
          <AccordionContent className="bg-[#181818]">
          ACE is an educational nonprofit that prides itself on having chapters that create targeted study material for other students. In an ACE Chapter, the goal is to get as many students access to study materials. These study materials include practice tests uploaded on the ACE website, slideshows, and other review material. By doing this, you'll be making an impact just like the other ACE Chapters! Additionally when creating material, ACE prides itself on academic integrity. Ensure that all material is original and verify practice tests and material with teachers to ensure correct information.


          </AccordionContent>
          </div>
        </AccordionItem>
        <AccordionItem value="item-3">
            <div className="bg-[#181818] rounded-md">
          <AccordionTrigger className="text-white bg-[#181818]">How do I run an ACE Chapter?</AccordionTrigger>
          <AccordionContent className="bg-[#181818]">
          By creating a chapter, you have numerous roles need to be filled. Open up an application form for leadership positions, officer positions, and representative positions and select a team. Then, assign the roles correspondingly. To run a chapter successfully, ensure tasks are being delegated and roles are being performed properly. Additionally, promote study sessions and get other students to join and engage with the material to grow the impact of your chapter.


          </AccordionContent>
          </div>
        </AccordionItem>
      </Accordion>
    )
  }
  