import '../../App.css';
//import FentImage from '../../images/fent.jpeg'
//import Kaytra from '../../images/kaytra.png'
import Homepage from '../../images/homepage.png'
import './home.css';
import AceFlyer from '../../images/aceflyer.png'
//import Button from 'rsuite/Button';
import 'rsuite/Button/styles/index.css';
import { useNavigate } from 'react-router-dom';
import {Card, CardHeader, CardBody, CardFooter} from "@heroui/card";
import ThreeCircles from '../../components/threecircles';

export const Home = () => {
    const navigate = useNavigate();

    return(
        <div>
            
            <div className="home-container">
            <br />
            <div className="container">
                <div className="animation-wrapper">
                <div className="typed-out"></div>
                </div>
                <br></br>
            </div>
            
            <div className="content-grid">
                <div className="image-container">
                    <img src={Homepage} alt="homepage" className="homeimage"/>
                    <br></br><br></br>
                </div>
                <div className="text-container">
                    <h3 className="study-classes">Studying <br /> <span className="all-in">centralized.</span></h3>
                    
                </div>
            </div>
            <div className="content-grid">
                <div className="text-container centered">
                    <h3><a href="/curriculum" className="underlinehome">Explore our Curriculum</a></h3>
                </div>
            </div>
            <br /><br />
            <hr />
            <br />
            <div>

                <h1 className="mb-4 text-4xl font-inter leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white"><span className="gradient-text-ace">A student-based approach.</span></h1>
                <div className="/underlined">
                <p className="mb-6 text-lg font-inter text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">For the students. For the teachers. For success.</p>
                </div>
                <a href="/aboutus" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-red-700 rounded-xl hover:bg-red-500 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900 no-underline">
                Learn more
                <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
                </a>

        
            </div>
       
            <div className="extragrid">
                <div className="textcontainer2">
                    <div className="infoblock">
                        <h2 className="expand">Expand</h2>
                        <p>ACE is always looking to expand its reach. If you’re interested, you may apply for a charter at any time. </p>
                        <a href="/startchapter" className="underlined">Learn more</a>
                    </div>
                    <div className="infoblock">
                        <h2 className="accessible">Content</h2>
                        <p>Our study material is accessible to everyone. We ensure our curriculum is up to date, accurate, and applicable. </p>
                        <a href="/services" className="underlined">Learn more</a>
                    </div>
                    <div className="infoblock">
                        <h2 className="whyace">Why ACE?</h2>
                        <p>Sometimes textbooks and homework aren't enough. ACE solves that problem.</p>
                        <a href="/aboutus" className="underlined">Learn more</a>
                    </div>
                    <div className="imagecontainer2">
                        <img src={AceFlyer} alt="extraimage" className="aceflyer" />
                    </div>
                </div>
                <br />
                <br />
                <hr className="homehorizontal"></hr>
                
            </div>
            <ThreeCircles />
            <br />
            <hr className="homehorizontal"></hr>
            <br />
            <div>
                <h2 className="join-us">Join us!</h2>
                <h1 className="custom-chapter">Create a Chapter<br /> <mark className="custom-heading-highlight">Near You</mark></h1>
                <button onClick={() => navigate("/startchapter")} type="button" className="custom-red-button-rounded">Apply Now!</button>
                <br /><br /><br />
            </div>
            <hr />
        </div>
            
            
        
        </div>
    );
}