import React, { useState } from 'react';
import axios from 'axios';

interface ApiResponse {
    message: string;
    data: {
    title: string;
    description: string;
    hyperlink?: string;
    pdflink ?: string;
    selectedCourseOption: string;
    selectedSchoolOption: string;
    selectedUnitOption: string;
    }

}

const AceComponent = () => {
  const [response, setResponse] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleTestApi = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post<ApiResponse>('http://localhost:5001/ace/test', {
        title: 'Calc BC Practice Test',
        description: 'Unit 7 Practice Test ',
        selectedCourseOption: 'AP Calculus BC',
        selectedSchoolOption: 'Plano East Senior High',
        selectedUnitOption: 'Unit 7'
      });
      setResponse(response.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || 'An error occurred');
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <button
        onClick={handleTestApi}
        disabled={loading}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
      >
        {loading ? 'Testing...' : 'Test API'}
      </button>

      {error && (
        <div className="mt-4 p-4 bg-red-100 text-red-700 rounded">
          Error: {error}
        </div>
      )}

      {response && (
        <div className="mt-4 p-4 bg-green-100 text-green-700 rounded">
          <h3 className="font-bold">Response:</h3>
          <pre className="mt-2 whitespace-pre-wrap">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default AceComponent;

/**import {useForm} from 'react-hook-form'
//import {useState} from 'react'
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {addDoc, collection} from 'firebase/firestore';
import {auth, db} from '../../config/firebase';
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from 'react-router-dom'
import '../../App.css';
//import {useState, useRef} from 'react';
import './post.css';


interface CreateFormData {
    title: string;
    description: string;
    hyperlink?: string;
    pdflink ?: string;
    selectedCourseOption: string;
    selectedSchoolOption: string;
    selectedUnitOption: string;

}


export const CreateForm = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    
    const schema = yup.object().shape({
        title: yup.string().required("You must add a title."),
        description: yup.string().required("Please add a short description."),
        hyperlink: yup.string().url("Must be a valid URL. Make sure to add https://"),
        pdflink: yup.string().url("Must be a valid PDF URL. Make sure to add https://"),
        selectedCourseOption: yup.string().required('Please select a course'),
        selectedSchoolOption: yup.string().required('Please select a school.'),
        selectedUnitOption: yup.string().required('Please select a unit.'),
    });

    const{register, handleSubmit, formState: {errors},} = useForm<CreateFormData>({
        resolver: yupResolver(schema),
    })

    const postsRef = collection(db, "posts")

    const onCreatePost = async (data: CreateFormData) => {
        await addDoc(postsRef, {
            title: data.title,
            description: data.description,
            hyperlink: data.hyperlink,
            pdflink: data.pdflink,
            username: user?.displayName,
            userId: user?.uid,
            selectedCourseOption: data.selectedCourseOption,
            selectedSchoolOption: data.selectedSchoolOption,
            selectedUnitOption: data.selectedUnitOption,
        });

        navigate("/");


    }


    return(
        <div>
        <h1><span className="gradient-text-ace">Upload Assignment</span></h1>
        <form onSubmit={handleSubmit(onCreatePost)} className="forms-wrapper">
            <div className="assignment-container">
            <input placeholder="Title..." {...register("title")} className="titleinput"/>
            <p style={{color:"red"}}>{errors.title?.message}</p>
            <textarea placeholder="Write a short description here"{...register("description")}  className="textArea"/>
            <p style={{color: "red"}}>{errors.description?.message}</p>
            <br />
            
            <input placeholder="Paste hyperlink here" {...register("hyperlink")} className="hyperlink-width" />
            <p style={{color:"red"}}>{errors.hyperlink?.message}</p>
            <input placeholder="Paste PDF link here" {...register("pdflink")} className="hyperlink-width" />
            <p style={{color: "red"}}>{errors.pdflink?.message}</p>
            </div>
            <br />
            <div className="course-container">
            <h3>Course:</h3>
            <select {...register("selectedCourseOption")} className="uploadcursor">
                <option value="AP Computer Science Principles">AP Computer Science Principles</option>
                <option value="AP Human Geography">AP Human Geography</option>
                <option value="Add New Course">Add New Course</option>
            </select>

            <p style={{color:"red"}}>{errors.selectedCourseOption?.message}</p>
            <h3>School:</h3>
            <select {...register("selectedSchoolOption")} className="uploadcursor">
                <option value="Plano East Senior High">Plano East Senior High</option>
                <option value="Plano West Senior High">Plano West Senior High</option>
                <option value="John Paul II High School">John Paul II High School</option>
                <option value="Independence High School">Independence High School</option>
            </select>
            <p style={{color:"red"}}>{errors.selectedSchoolOption?.message}</p>
            <h3>Unit:</h3>
            <input {...register("selectedUnitOption")} className="hyperlink-width"/>
            <p style={{color:"red"}}>{errors.selectedUnitOption?.message}</p>
            
            <br></br><br></br>
            <input type="submit" className="custom-red-button" value="Post" />            <br />
            
            </div>
           
        </form>
        </div>
    );
}**/