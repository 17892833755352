import { Carousel, Card } from '../components/ui/apple-cards-carousel';
import StudySession from '../images/studysession2.png'
import StudySessionPopup from '../images/studysession.png'
import ChemReview from '../images/chem2.png'
import Tutor from '../images/stocktutor.png'
import './carousel.css'

const ServiceCarousel = () => {
  const cards = [
    {
      src: StudySession,
      title: "Study Sessions",
      category: "01",
      content: 
      <div>
        <div className="text-black">Learn from your peers! Our representatives lead study sessions, where students work together through concepts learned in class.</div>
        <br />
        <div><img src={StudySessionPopup} alt="study session" className="imageadjust"/></div>
      </div>
    
    },
    {
      src: ChemReview,
      title: "Review Material",
      category: "02",
      content: 
      <div>
        <div className="text-black">
          Find material for your classes! We create many study resources such as slideshow presentations, practice tests, and more.
        </div>
        <br />
        <div>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTdXsNN-Tcs1DZPWi3keheFHbXe-J4lNgsge1BlPgVK-KMmX4DKkcOOnXOUKiG8GY0IAaTSPg3QsPvn/embed?start=false&loop=false&delayms=3000" title="acereview" frameBorder={0} width={480} height={299} allowFullScreen={true} />
        </div>
      </div>
    }
    ,
    {
      src: Tutor,
      title: "One-on-One tutoring",
      category:"03",
      content:
      <div className="text-black">
        Need help? Our one-on-one tutoring sessions can provide a more personalized learning and study experience.
      </div>
    }
  ];

  const carouselItems = cards.map((card, index) => (
    <Card 
      key={index}
      card={card}
      index={index}
      layout={true}
    />
  ));

  return (
    <Carousel items={carouselItems} />
  );
};

export default ServiceCarousel;