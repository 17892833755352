import '../../App.css';
import './services.css';
import StudySession from '../../images/studysession.png';
import StockTutor from '../../images/stocktutor.png'
import {Card, CardHeader, CardBody, CardFooter} from "@heroui/card";
import ThreeCircles from '../../components/threecircles';
import ServiceCarousel from '../../component/carousel';
export const Services = () => {
    const serviceBlocks = [
        { number: "01", title: "Study Sessions", description: "Learn from your peers! Our representatives lead study sessions, where students work together through concepts learned in class. ", borderColor: "red" },
        { number: "02", title: "Review Material", description: "Find material for your classes! We create many study resources such as slideshow presentations, practice tests, and more.", borderColor:"red" },
        { number: "03", title: "One-on-One tutoring", description: "Need help? Our one-on-one tutoring sessions can provide a more personalized learning and study experience.", borderColor:"red" }
    ]; 
    
    return(
    
    <div>
            
       <h1>Our Services</h1>
       <p className="-mb-8">ACE offers three main services: Study Sessions, Review Materials, and One-on-One tutoring. Click on the cards below to learn more!</p>
       <ServiceCarousel />
        
    
       
        <br />


    </div>
    );
}

/**
 *         <div className="home-container">
            <div className="content-grid">
                <div className="image-container">
                    <img src={StudySession} alt="Study Session" className="homeimage"/>
                    <br></br><br></br>
                </div>

                <div className="text-container">
                    <h3 className="study-classes">Study <br /> <span className="all-in">Sessions</span></h3>
             
                    </div>
                    </div>
                </div>
                 <div className="home-container">
                    <div className="content-grid">
                        <div className="text-container">
                            <h3 className="study-classes">Review <br /> <span className="all-in">Material</span></h3>
                        </div>
                        
                        <div className="image-container">
                            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTdXsNN-Tcs1DZPWi3keheFHbXe-J4lNgsge1BlPgVK-KMmX4DKkcOOnXOUKiG8GY0IAaTSPg3QsPvn/embed?start=false&loop=false&delayms=3000" title="acereview" frameBorder={0} width={480} height={299} allowFullScreen={true} />
                            <br></br><br></br>
                        </div>
                 
                    </div>
                 </div>
        
                 <div className="home-container">
                    <div className="content-grid">
                        <div className="image-container">
                            <img src={StockTutor} alt="tutoring" className="homeimage"/>
                            <br></br><br></br>
                        </div>
        
                        <div className="text-container">
                            <h3 className="study-classes">One-on-One <br /> <span className="all-in">Tutoring</span></h3>
                        </div>
                    </div>
                 </div>
 */