import './chapters.css'
import PlanoEast from '../../images/homepage.png'
import PlanoSenior from '../../images/planosenior.png'
import ComingSoon from '../../images/soon.png'
export const Chapters = () =>{
    return(
        <div>
            <h1 className="gradient-text-chapters">Texas Chapters</h1>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={PlanoEast} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={PlanoSenior} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Plano East Senior High School</h2>
                        <p>President: Ayan Mishra</p>
                        <p>Vice Presidents: Maha Rizvi, Swathi Parameshwar</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Plano Senior High School</h2>
                        <p>President: Benedict Premilan</p>
                        <p>Vice Presidents: Ashwini</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Plano West Senior High School</h2>
                        <p>President: Piyush Kumar</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">McMillen High School</h2>
                        <p>President: Cody Trainer</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Williams High School</h2>
                        <p>President: Grace Jobe</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Flower Mound High School</h2>
                        <p>President: Dhruv Pabari</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Clark High School</h2>
                        <p>President: Dharshana Balaji</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Booker T. Washington High School</h2>
                        <p>President: Emma Hwang</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">The Hockaday School</h2>
                        <p>President: Emma Lin</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Allen High School</h2>
                        <p>President: Zanir Tharwani</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Ranchview High School</h2>
                        <p>President: Tarun Kumar Vijay</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Green Level High School</h2>
                        <p>President: Srinivas Tirunagari</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Rock Hill High School</h2>
                        <p>President: Deethya Gani</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Centennial High School</h2>
                        <p>President: Adhvaith Ananth</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Paramus High School</h2>
                        <p>President: Ayush Patel</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Heritage High School</h2>
                        <p>President: Moksha Paramathmuni</p>
                        <p>Vice Presidents: Lindsey Zhao, Ashwini</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Jasper High School</h2>
                        <p>President: Sampada Bapat</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Vines High School</h2>
                        <p>Co-Presidents: Craig Benson, Fiona Bolt</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Liberty High School</h2>
                        <p>Co-Presidents: Ariesha Chitre, Aditri Pandey</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Independence High School</h2>
                        <p>Co-Presidents: Kalaanidhi Sundar, Sumukh Chetan</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Evergreen Valley</h2>
                        <p>Co-Presidents: Isha Singh, Noor Mandair</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Panther Creek High School</h2>
                        <p>Co-Presidents: Srika Penmatsa, Aishwarya Das</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Lone Star High School</h2>
                        <p>Co-Presidents: Melonie Vu, Yena Oh</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Coppell High School</h2>
                        <p>Co-Presidents: Kavya Karthik, Sanchi Srivastava</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Laguna Creek High School</h2>
                        <p>Co-Presidents: Vevean Nguyen, Nguyen Nguyen</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Wakeland High School</h2>
                        <p>President: Shreya Goyle</p>
                    </div>
                </div>
            </div>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={ComingSoon} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={ComingSoon} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Wylie East High School</h2>
                        <p>President: Shayaan Younas</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">High School</h2>
                        <p>President: Benedict Premilan</p>
                        <p>Vice Presidents: Lindsey Zhao, Ashwini</p>
                    </div>
                </div>
            </div>
        </div>
    )
}