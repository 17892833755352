import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App.css';
import {Main} from './pages/main/main';
import {Login} from "./pages/login";
import {Navbar} from "./components/navbar";
import {CreatePost} from './pages/create-post/create-post';
import {AboutUs} from './pages/about-us/about-us';
import { StartChapter } from './pages/start-chapter/start-chapter';
import { Home } from './pages/home/home';
//import {Footer} from './components/footer';
import {Footer2} from './components/footer2';
import {CreateContactForm} from './pages/contact-us/contact-us';
import {Services} from './pages/services/services';
import {ReportIncident} from './pages/report-incident/report-incident'
import {CustomDropdownPage} from './pages/test/test'
import {Chapters} from './pages/chapters/chapters'
import {NotFound} from './components/notfound'
import {Navigate} from 'react-router-dom'
import AceComponent from './components/testapi'
import TestComponent from './components/test2';


function App() {
  
  const getSubdomain = () =>{
    const hostname = window.location.hostname;

    if (hostname === 'localhost' || hostname === '127.0.0.1'){
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('subdomain');
    }

    if (hostname.includes('web.app') || hostname.includes('firebaseapp.com')){
      return null;
    }

    const splitDomain = hostname.split('.');
    return splitDomain.length > 2 ? splitDomain[0] : null;
  };

  const subdomain = getSubdomain();

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          {subdomain === 'portal' ? (
           <>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/posts" element={<Main />}/>
            <Route path="/createpost" element={<CreatePost />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/startchapter" element={<StartChapter />} />
            <Route path="/contactus" element={<CreateContactForm />} />
            <Route path="/services" element={<Services />} />
            <Route path="/reportincident" element={<ReportIncident />} />
            <Route path="/test" element={<CustomDropdownPage />} />    
            <Route path="/chapters" element={<Chapters />} />
            <Route path="*" element={<NotFound />} />
          </>
          ) : (
            <>
          <Route path="/" element={<Home />} />
          <Route path="/posts" element={<Main />}/>
          <Route path="/logintest" element={<Login />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/startchapter" element={<StartChapter />} />
          <Route path="/contactus" element={<CreateContactForm />} />
          <Route path="/services" element={<Services />} />
          <Route path="/reportincident" element={<ReportIncident />} />
          <Route path="/test" element={<CustomDropdownPage />} />    
          <Route path="/chapters" element={<Chapters />} />
          <Route path="/testing" element={<TestComponent />} />
          <Route path="/acetesting" element={<AceComponent />} />
          <Route path="*" element={<NotFound />} />
          </>
          )}
        </Routes>
      </Router>
   
      <Footer2 />
    </div>
  );
}

export default App;
