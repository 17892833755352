import '../../App.css';
import './start-chapter.css'
//import {TabsHero} from '../../component/servicetab'
import { AccordionDemo } from '../../component/accordion';
export const StartChapter = () => {
    return(
        <div>
            <h1>Chapter Information</h1>
            <p>Do you want to join the academic community of ACE? Let's start with you!</p>
            <p>If you would like to open a chapter, please complete the following form by clicking <a href="https://forms.gle/Edr4fRU8mwSn494M8" target="/blank" className="hype">here</a> or scroll down to fill out the form!</p>
            
            
            <AccordionDemo />
            <h1 className="mt-8">Check out our guides!</h1>
            <div className="guide">
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQipUAQ0BUjhIh-XRkFI_L2THJ9JPXiVpwZq6i_bUjsXyPyS-c7NG6WcoQq9vD3RBYQyVBWFqEeg7Zi/embed?start=false&loop=false&delayms=60000" frameBorder={0} width={600} height={569} className="mr-4"></iframe>
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTJ0sRcIRVO8HEsWNlh0qyU89z1kK1FxZRTE_CyDbTC6lwYpOfOebnNRf9ygcpElN-H1vEuefCa65s0/embed?start=false&loop=false&delayms=60000" frameBorder={0} width={600} height={569} className="ml-4"></iframe>
            </div>
            <br />
            <h1>Want to be apart of the growing movement? Apply for ACE here!</h1>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe4y_aC-tED45xGPN4TGTH2habhko20OGwQr7c-Zt8hQQ0MGQ/viewform?embedded=true" width={640} height={500} frameBorder={0} marginHeight={0} marginWidth={0} title="aceform">Loading…</iframe>
        </div>
    );
}